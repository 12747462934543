export const useApiHeaders = async (internal: boolean = false) => {
  const config = useRuntimeConfig();
  const user = await getCurrentUser();
  let token;
  if (user) {
    token = await user.getIdToken();
  }

  if (internal)
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        iuid: sessionStorage.getItem("iuid"),
      },
    };

  return {
    headers: {
      Authorization: `Bearer ${token}`,
      iuid: sessionStorage.getItem("iuid"),
    },
    baseURL: config.public.baseUrl,
  };
};
